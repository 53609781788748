//THEME COLOURS
$theme-orchid: #851481;
$theme-purple: #48194f;
$theme-lilac: #e9e0f2;
$theme-turquoise: #3bc7d7;
$theme-denim: #325671;

// REUSABLE CLASSSES

.line-height {
  line-height: 1.5;
}

.flex-box {
  display: flex;
}

.centered {
  justify-content: center;
}

.items-center {
  align-items: center;
}

.column {
  flex-direction: column;
  margin: 0 auto;
}

.black-border {
  border: 2px solid black;
  padding: 10px;
  border-radius: 10px;
}

.turquoise {
  color: $theme-turquoise;
}
.turquoise:hover {
  color: rgb(106, 233, 255);
  transform: scale(1.1)
}

.large-icon {
  font-size: 2rem;
}

.mind-the-gap {
  margin-right: 10px;
}

.code-block {
  background-color: rgba(0, 0, 0, 0.7);
  font-size: 1.3rem;
  color: $theme-turquoise;
  padding: 2px;
  border-radius: 7px;
  margin: 0 auto;
  max-width: 500px;
  letter-spacing: 1px;
  font-family: SFMono-Regular, Consolas, Liberation Mono, Menlo, monospace;
}

.linked-in {
  color: white;
  text-decoration: none;
  background-color: $theme-denim;
  padding: 3px 5px;
  border-radius: 5px;
}

.linked-in:hover {
  background-color: rgb(61, 106, 143);
}

.bio {
  font-size: 1.1rem;
  max-width: 850px;
  margin: 0 auto;
  margin-bottom: 1rem;
  padding: 0 15px;
}

@media screen and (max-width: 472px) {
  .bio {
    font-size: 1rem;
  }

}

.bio-box {
  max-width: 850px;
  margin: 0 auto;
}

//ONE USE CLASSES

.small-logo {
  height: 30px;
  width: auto;
  padding-right: 10px;
}

// box model hack
* {
  box-sizing: border-box;
} 

//JSX ELEMENTS

body {
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  margin: 0 auto;
  background-color: white; 
}

main {
  width: 90%;
  margin: 0 auto;
  padding-top: 5.5rem;
  color: black;
  //background-color: $theme-lilac;
}

h1 {
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 3rem;
  margin: 0;
  font-family: 'Squada One', cursive;
}

h2 {
  font-size: 2rem;
  font-family: 'Oswald', sans-serif;
  letter-spacing: 1px;
}

//NAV BAR COMPONENT
nav {
  text-transform: uppercase;
  display: flex;
  align-items: center;
  color: white;
  background-color: black;
  box-shadow: 0 0 5px 1px $theme-turquoise;
  position: fixed;
  width: 100%;
  z-index: 2;
  top: 0;
  border-bottom: 1px solid $theme-turquoise;
  margin: 0;
  padding: 5px 10px 5px 15px;


  .toggle-btn {
    display: none;
    cursor: pointer;
    margin: 0 5px;
    font-weight: bold;
    line-height: 0.35;
  }

  .toggle-btn:hover {
    color: rgb(171, 171, 171);
  }

  .open-btn {
    font-size: 1.3rem;
    .line-one, .line-two, .line-three {
      transition: 0.3s;
      transform: rotate(90deg);
    } 
  }

  .close-btn {
    font-size: 1.4rem;
    .line-one {
      transition: 0.3s;
      transform: rotate(135deg);
    }
    .line-two {
      transition: 0.3s;
      transform: rotate(45deg);
      margin-top: -10px;
    }
    .line-three {
      display: none;
    } 
  }

  .logo {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    p {
      font-family: 'Monoton', cursive;
      color: white;
      font-weight: bold;
      font-size: 2rem;
      margin: 0;
    }
  }
  
  div {
    margin: 0;
    padding: 0;
  }

  ul {
    display: flex;
    list-style-type: none;
    width: 70%;
    justify-content: space-evenly;
    align-items: center;
    margin: 0 auto;
    padding: 0;
  }

  li {
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 1px;
  }

  li:hover {
    a {
      color: $theme-turquoise;
    }
    p {
      animation-name: spin;
      animation-duration: 0.5s;
      animation-iteration-count: 1;
    }
  }

  a {
    text-decoration: none;
    color: white;
    font-family: 'Squada One', cursive;
    font-size: 1.7rem;
    margin: 0;

    p {
      font-size: 1rem;
      margin-left: 5px;
      //padding-top: 3px;
      transform: rotate(0deg);
      color: $theme-turquoise;
    }
    //font-weight: bold;
  }

  li a {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  i {
    font-size: 1.7rem;
  }
}

// NAV BAR MEDIA QUERIES
@media screen and (max-width: 1245px) {
  nav {
    ul {
      width: 60%;
    }
  }
}

@media screen and (max-width: 1000px) {
  nav {
    ul {
      width: 50%;
    }
  }
}

@media screen and (max-width: 800px) {
  nav {
    padding: 5px 2px 5px 10px;

    a {
      font-size: 1.3rem;
    }

    .logo p {
      font-size: 1.5rem;
    }

  }
}

@media screen and (max-width: 630px) {
  nav {
    padding: 5px 10px;
    display: block;

    .close-btn {
      display: block;
    }

    .open-btn {
      display: block;
    }

    .hide-menu {
      display: none;
    }

    li p {
      display: none;
    }

    li a span {
      margin: 13px auto;
    }

    ul {
      display: block;
      margin: 10px auto 0 auto;
      width: 90%;
    }

    .mobile-divider {
      border-top: 1px solid grey;
    }

    a {
      font-size: 1.3rem;
    }

    .logo p {
      font-size: 1.5rem;
    }

    .social-icons {
      position: fixed;
      top: 5px;
      right: 7px;
    }

  }
}

@media screen and (max-width: 415px) {
  nav {
    .logo p {
      font-size: 1.2rem;
    }

    .social-icons {
      i {
        font-size: 1.5rem;
      }

      .large-icon {
        font-size: 1.8rem;
      }
      
      position: fixed;
      top: 5px;
      right: 7px;
    }

  }
}

// HOME
.home {
  margin: 0 auto;
  text-align: center;
  height: 100vh;
  overflow: hidden;
  background-image: url('../images/anton-maksimov.jpeg');
  background-size: cover;
  

  h1 {
    font-size: 2rem;
  }

  .hero {
    color: white;
    padding: 8rem 0 0 0;
    // background-image: url('../images/anton-maksimov.jpeg');
    // background-size: cover;
    margin: 0 auto;
    width: 100vw;

    h1 {
      font-family: 'Monoton', cursive;
      font-size: 4rem;
      margin: 1rem auto;
    }

    h2 {
      //background-color: black;
      background: rgba(0, 0, 0, 0.392);
      //border: 2px solid $theme-turquoise;
      text-transform: uppercase;
      color: white;
      padding: 10px 0;
      width: 100%;
      margin-top: 8rem;
      margin-bottom: 0;
      letter-spacing: 2px;
      font-size: 1.5rem;
    }

    h4 {
      margin: 1rem auto;
      font-weight: normal;
      font-size: 2rem;
      font-family: 'Oswald', sans-serif;
      letter-spacing: 1px;
    }

    // HERO MEDIA QUERIES

    @media screen and (max-width: 950px) {
      h1 {
        font-size: 3rem;
      }
      h4 {
        font-size: 1.7rem;
      }
    }

    @media screen and (max-width: 650px) {
      h1 {
        font-size: 2.3rem;
      }
      h4 {
        font-size: 1.5rem;
      }
      .code-block {
        font-size: 1.1rem;
        width: 400px;
      }
    }

    @media screen and (max-width: 472px) {
      padding-top: 5rem;

      h2 {
        margin-top: 3rem;
      }

      .code-block {
        font-size: 1rem;
        width: 350px;
      }

    }
  }



  .contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: linear-gradient($theme-turquoise, $theme-denim);
    height: 55vh;
    padding-top: 3rem;

    div {
      letter-spacing: 1px;
      font-family: SFMono-Regular, Consolas, Liberation Mono, Menlo, monospace;
      margin-bottom: 1.5rem;
    }

    i {
      font-size: 1.5rem;
      margin-right: 7px;
    }
  
    a {
      font-size: 1.1rem;
      text-decoration: none;
      color: $theme-purple;
      //border-bottom: 2px solid black;
      padding: 5px 0 5px 0;
      border-bottom: 2px solid transparent;
      transition: border-bottom 1s;
      //border-radius: 0 0 5px 0;
    }
  
    a:hover {
      color: $theme-orchid;
      border-bottom: 2px solid rgb(170, 0, 255);
    }
    
    // CONTACT SECTION MEDIA QUERIES
    @media screen and (max-width: 472px) {
      padding-top: 1rem;

      div {
        letter-spacing: 0;
      }

      i {
        font-size: 1.3rem;
        margin-right: 6px;
      }
    
      a {
        font-size: 1rem;
        padding-bottom: 0;
        border-bottom: none;
      }
    
      a:hover {
        color: $theme-purple;
        border-bottom: none;
      }
    }
  }

}

// PROJECTS
.projects-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  .project-category {
    background: linear-gradient($theme-turquoise, $theme-denim);
    color: white;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 2rem;
    margin-bottom: 2rem;
    border-radius: 10px;

    .project-category-title {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
    }

    p {
      display: block;
      margin-top: -10px;
      font-weight: bold;
      font-size: 0.8rem;
    }
  }

  .project {
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    background-color: white;
    color: black;
    margin-bottom: 2rem;
    border-top: 2px solid $theme-denim;
    border-left: 2px solid $theme-denim;
    border-right: 2px solid $theme-denim;
    border-bottom: 2px solid $theme-denim;
  }

  h3 {
    font-size: 2rem;
    font-family: 'Oswald', sans-serif;
    letter-spacing: 1px;
  }

  ul {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    list-style-type: none;
    padding: 0;

    li {
      font-size: 2rem;
      margin: 15px;

      i, h5 {
        font-size: 2.5rem;
        margin: 0;
      }

      p {
        font-size: 1rem;
        margin-bottom: 0;
      }
    }
  }

  .project-btn-wrapper {
    display: flex;
    justify-content: center;
    padding: 1rem 0;

    a {
      text-decoration: none;
      color: white;
      margin: 0 10px;
      background: linear-gradient($theme-turquoise, $theme-denim);
      padding: 7px 22px;
      border-radius: 10px;
    }

    a:hover {
      color: white;
      background: linear-gradient($theme-denim, black);
    }
  }
  .border {
    border-left: 2px solid $theme-denim;
  }


  figure {
    width: 598px;
    margin: 0 auto;
    

    img {
      width: 100%;
      margin: 0 auto;
      border-bottom: 2px solid $theme-denim;
    }
  }
}

// PROJECTS PAGE MEDIA QUERIES
@media screen and (max-width: 950px) {
  .projects-page {

    .project {
      display: block;
    }

    .border {
      border-top: 2px solid $theme-denim;
      border-left: none;
    }

    figure {
      width: 100%;
    }
  }
}

@media screen and (max-width: 472px) {
  .projects-page {
    .project-category {
      h2 {
        font-size: 1.3rem;
      }

      .super-long-title {
        font-size: 1.1rem;
      }


      p {
        font-size: 0.8rem;
        font-weight: normal;
      }
    }
    

    h3 {
      font-size: 1.6rem;
      margin: 1rem;
    }

    p {
      font-size: 1rem;
    }

    li p {
      margin-top: 0.5rem;
    }

    
  }
}


// SKILLs
.skills-page {

  ul {
    display: flex;
    flex-wrap: wrap;
    //justify-content: center;
    list-style-type: none;
    margin: 0 auto;
    padding: 0;
  }

  li {
    margin: 20px;
    cursor: default;

    i {
      font-size: 40px;
      margin-bottom: -10px;
    }

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  li:hover{
    transform: scale(1.2);
  }
}

// SKILLS PAGE MEDIA QUERIES 
@media screen and (max-width: 472px) {
  .skills-page {

    h2 {
      margin-bottom: 0.7rem;
    }
  
    li {
      margin: 10px 15px;
      cursor: default;
  
      i {
        font-size: 35px;
      }

      p {
        font-size: 0.9rem;
      }
    }
  
    li:hover{
      transform: scale(1);
    }
  }

}



// STYLING TIMELINE
.timeline-section {

  .history {
    align-items: center;
    margin: 2rem 0;
    font-weight: bold;
  }

  h2 {
    text-align: center;
    margin-bottom: 0;
  }
  h4 {
    font-size: 1.2rem;
  }
  p {
    font-size: 1rem;
    padding: 0 20px;
    margin: 0;
  }
  figure {
    height: 50px;
    margin: 5px auto;

    img {
      height: 100%;
    }
  }
  a {
    text-decoration: none;
    color: $theme-denim;
    font-weight: bold;
  }
  a:hover {
    color: $theme-turquoise;
  }
  .readme {
    display: block;
    text-decoration: none;
    color: black;
    background-color: white;
    border: 2px solid black;
    padding: 5px 20px;
    border-radius: 10px;
    width: 50%;
    margin: 1rem auto 0 auto;
  }
  .readme:hover {
    color: white;
    background-color: black;
  }
}


/*************Timeline Section *********************/
/* The actual timeline (the vertical ruler) */
.timeline {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
}

.timeline h4 {
  margin: .3rem auto;
}

.timeline article {
  list-style-type: none;
  font-family: 'Roboto Condensed', sans-serif;
}

/* The actual timeline (the vertical ruler) */
.timeline::after {
  content: '';
  position: absolute;
  width: 6px;
  background-color: black;
  top: 0;
  bottom: 0;
  left: 50%;
  margin-left: -3px;
}

/* Container around content */
.container {
  padding: 10px 40px;
  position: relative;
  background-color: inherit;
  width: 50%;
  text-align: center;
  color: black;
  /* cursor: zoom-in; */
}

// .container:hover {
  //color: white;
  //font-size: 1.15rem;
  //box-shadow: 1px 1px 5px 2px rgba(130, 130, 130, 0.473);
  //background-color: $theme-lilac; 
// }

/* The circles on the timeline */
.container::after {
  content: '';
  position: absolute;
  width: 25px;
  height: 25px;
  right: -17px;
  background-color: white;
  border: 4px solid black;
  top: 15px;
  border-radius: 50%;
  z-index: 1;
}

/* Place the container to the left */
.left-panel {
  left: 0;
  padding-left: 0;
}

/* Place the container to the right */
.right-panel {
  left: 50%;
}

/* Add arrows to the left container (pointing right) */
.left-panel::before {
  content: " ";
  height: 0;
  position: absolute;
  top: 22px;
  width: 0;
  z-index: 1;
  right: 30px;
  border: medium solid black;
  border-width: 10px 0 10px 10px;
  border-color: transparent transparent transparent black;
}

/* Add arrows to the right container (pointing left) */
.right-panel::before {
  content: " ";
  height: 0;
  position: absolute;
  top: 22px;
  width: 0;
  z-index: 1;
  left: 30px;
  border: medium solid black;
  border-width: 10px 10px 10px 0;
  border-color: transparent black transparent transparent;
}

/* Fix the circle for containers on the right side */
.right-panel::after {
  left: -16px;
}
/* Media queries - Responsive timeline on screens less than 600px wide */
@media screen and (max-width: 600px) {
  .timeline-section {
    article {
      border: none;
    }

    .container:hover {
      //color: white;
      //font-size: 1.15rem;
      box-shadow: none;
      //background-color: $theme-lilac; 
    }
  }
  
  /* Place the timelime to the left */
    .timeline::after {
      left: 31px;
    }
  
  /* Full-width containers */
    .container {
      width: 100%;
      padding-left: 70px;
      padding-right: 25px; 
    }
  
  /* Make sure that all arrows are pointing leftwards */
    .container::before {
      left: 60px;
      border: medium solid gray;
      border-width: 10px 10px 10px 0;
      border-color: transparent gray transparent transparent;
    }
    .left-panel {
      left: 0;
    }
  
  /* Make sure all circles are at the same spot */
    .left-panel::after, .right-panel::after {
      left: 15px;
    }
  
  /* Make all right containers behave like the left ones */
    .right-panel {
      left: 0%;
    }
}

// ABOUT ME PAGE MEDIA QUERIES
@media screen and (max-width: 472px) {

  .black-border h1 {
    margin-bottom: 0;
  }

  .timeline-section p {
      font-size: 1rem;
      padding: 0;
    }

}

// FOOTER PAGE
footer {
  display: flex;
  justify-content: center;
  background-color: $theme-denim;
  color: white;
  font-size: 0.8rem;
}


// FOOTER PAGE MEDIA QUERIES


// UNIVERSAL MEDIA QUERIES
@media screen and (max-width: 472px) {
  main {
    padding-top: 4rem;
    width: 93%;

    h1 {
      font-size: 2rem;
      margin-bottom: -10px;
    }

    h2 {
      font-size: 1.7rem;
    }
  }
}


// ANIMATIONS
@keyframes spin {
  0% { transform: rotate(0deg) }
  25% { transform: rotate(90deg) }
  50% { transform: rotate(180deg) }
  75% { transform: rotate(270deg) }
  100% { transform: rotate(360deg) }
}


